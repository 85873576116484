<script>
import { fetchPageBuilder, OptionsComponent } from '@drapejs/core';

import useMeta from "@/composables/useMeta";
import LoginForm from "../components/LoginForm";
import { buildUrlPathWithQuery } from "../utils";
import * as user from "../../connectors/litium/user";

export default {
  extends: OptionsComponent,
  components: {
    LoginForm,
  },
  data: () => ({
    requestPending: false,
    error: "",
  }),
  provide() {
    return {
      validateResetPasswordCode: this.validateResetPasswordCode,
    };
  },
  methods: {
    loginEvent(){
      if (typeof dataLayer === 'undefined') {
        return;
      }
      const dataToPush = {
        event: 'login',
      };

      dataLayer.push(dataToPush);
    },
    async login(form) {
      const requestArgs = this.buildCommandArgs({
        username: form.email,
        password: form.password,
      });

      const response = await this.executeCommand(
        user.commands.login,
        requestArgs
      );

      if (response.error === "NONE") {
        if(!response?.user?.canRepresentAnyOrganization){
          this.loginEvent();
        }
        await this.navigatePostLogin(response);
      } else {
        this.error = this.$globalTexts.login__login_failed;
      }
    },
    async forgotPassword(form) {
      const requestArgs = this.buildCommandArgs(form);
      const response = await this.executeCommand(
        user.commands.forgotPassword,
        requestArgs
      );
    },
    async resetPassword(form) {
      const requestArgs = this.buildCommandArgs(form);
      const response = await this.executeCommand(
        user.commands.resetPassword,
        requestArgs
      );

      if (response.error === "NONE") {
        if (response.redirectUrl) {
          await this.navigatePostLogin(response);
        }
      } else {
        this.error = this.$globalTexts.login__reset_failed;
      }
    },
    async navigatePostLogin(loginResponse) {
      const search = this.$route.query || {};
      const query = {
        ...search,
      };

      if (query.action) {
        if ("add" === query.action) {
          sessionStorage.setItem("add-to-cart", JSON.stringify(query));
        }

        if ("quickcart" === query.action) {
          sessionStorage.setItem("quickcart", JSON.stringify(query));
        }        

        if ("add-print" === query.action) {
          sessionStorage.setItem("add-print-to-cart", JSON.stringify(query));
        }        

        if ("my-pages" === query.action) {
          sessionStorage.setItem("my-pages", true);
        }

        delete query.action;
      }

      if (this.$route?.query?.redirect) {
        delete query.redirect;
        const clientRedirect = buildUrlPathWithQuery(
          this.$route.query.redirect,
          query
        );
        await this.$navigate(clientRedirect);
      } else if (loginResponse.redirectUrl) {
        const serverRedirect = buildUrlPathWithQuery(
          loginResponse.redirectUrl,
          query
        );
        await this.$navigate(serverRedirect);
      }
    },
    async validateResetPasswordCode(resetPasswordCode) {
      const requestArgs = this.buildCommandArgs({
        resetPasswordCode,
      });
      const response = await this.executeCommand(
        user.commands.validateResetPasswordCode,
        requestArgs
      );
      return response;
    },
    buildCommandArgs(formArgs) {
      return {
        ...formArgs,
        ...fetchPageBuilder(
          this.$route.protocol,
          this.$route.host,
          this.$route.pathname,
          { ...this.$route.query },
          ""
        ),
      };
    },
    async executeCommand(command, args) {
      try {
        this.error = "";
        this.requestPending = true;
        const response = await this.$invoke(command, args);
        return response;
      } catch (err) {
        this.error = err;
        return { err };
      } finally {
        this.requestPending = false;
      }
    },
  },
  created() {
    useMeta();
  },
};
</script>

<template>
  <div class="login">
    <login-form
      :request-pending="requestPending"
      :error="error"
      @login="login"
      @forgot-password="forgotPassword"
      @reset-password="resetPassword"
    />
  </div>
</template>

<style>
.login {
  position: relative;
  margin: 0 auto 2rem auto;
  width: calc(100% - 20px);
}

@media (--tabletAndDesktop) {
  .login {
    width: calc(100% - 20px);
    max-width: 363px;
  }
}
</style>
